<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="id ? '编辑项目' : '新增项目'"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 企微名称 -->
      <a-form-item
        label="企微名称"
        name="name"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.name"
        />
      </a-form-item>
      <!-- 企微账号 -->
      <a-form-item
        label="企微账号"
        name="account"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.account"
        />
      </a-form-item>
      <!-- 对应微小 -->
      <a-form-item
        label="对应微小"
        name="project"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <a-select
          v-model:value="formState.project"
          mode="multiple"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :filterOption="filterOption"
        >
          <a-select-option
            v-for="item in wxPlatformList"
            :key="`${item.project_id}-${item.name}`"
            :value="item.project_id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import Loading from '@/components/Loading'
import { resellerPlatformList, wechatAccountSave } from '@/api/copyright'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// id
let id = ref(null)
// 平台列表
let wxPlatformList = ref([])
// 表单
let formState = reactive({
  // 名称
  name: undefined,
  // 账号
  account: undefined,
  // 对应项目
  project: []
})


// 打开抽屉
function showDrawer (params) {
  // 展开
  visible.value = true
  // 赋值
  nextTick(() => {
    // 获取选择项列表
    getSelectList()
    if (params) {
      // id
      id.value = params.id
      // name
      formState.name = params.name
      // 分成比例
      formState.account = params.account
      // 项目属性
      formState.project = params.projectids || []
    }
  })
}

// 获取选择项列表
function getSelectList () {
  resellerPlatformList({ platform_id: 3 }).then(res => {
    const { code, msg, data } = res
    if (code === 0) {
      wxPlatformList.value = data
    }
  })
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 重置
    RefForm.value.resetFields()
    id.value = undefined
    visible.value = false
  }
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    const params = {
      id: id.value,
      ...formState
    }
    wechatAccountSave(params).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('操作成功')
        onClose()
        // 更新父组件
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.a-link {
  margin-left: 8px;
}
.form-item-clear {
  margin-bottom: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
  width: 118px;
  height: 148px;
  border-radius: 4px;
  overflow: hidden;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
.upload-item-select {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.upload-item-delete {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 18px;
  color: #8D8D8D;
  cursor: pointer;
  opacity: 0;
}
.form-upload-item:hover .upload-item-delete {
  opacity: 1;
}
.date-range {
  color: #8D8D8D;
  padding-left: 100px;
  margin-top: -15px;
}
</style>